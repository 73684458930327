<template>
    <div id="dashboard" class="d-flex flex-column h-100">
        <DashboardHeader>
            <div id="scenario-selector-group" class="order-1 order-lg-0 mt-3 mt-lg-0">
                <ScenarioSelector title="Zvolte srovnávaný interval:" id="refinterval" class="mb-2 mb-lg-0" />
                <ScenarioSelector title="Zvolte referenční interval:" id="studyinterval" />

                <b-button id="calculate-button" class="d-none d-lg-inline-block" size="sm" @click="calculate">
                    <b-icon icon="calculator-fill" variant="white" />
                </b-button>
                <b-button id="calculate-button" class="d-block d-lg-none w-100 mt-2" size="sm" @click="calculate">
                    <b-icon icon="calculator-fill" variant="white" />
                </b-button>
            </div>
        </DashboardHeader>

        <div class="d-flex flex-column flex-lg-row px-2 mr-lg-2">
            <div class="d-flex flex-column container-fluid px-0 col-lg-8 mr-lg-2" style="min-height: 100%;">
                <div class="h-100 mb-2">
                    <GasGraphTile id="gas-use-chart" graphTitle="Skutečná a modelová spotřeba plynu"
                        yAxisTitle="Denní spotřeba plynu (m<sup>3</sup>)" :graphData="gasUseData" />
                </div>
                <div class="h-100">
                    <GasGraphTile id="gas-save-chart" graphTitle="Kumulativní úspora plynu"
                        yAxisTitle="Celková úspora/ztráta (m<sup>3</sup>)" yAxis2Title="Denní úspora/ztráta (m<sup>3</sup>)"
                        :graphData="gasSaveData" />
                </div>
            </div>
            <div class="d-flex flex-column container-fluid px-0 mt-2 mt-lg-0 col-lg-4 mr-lg-2" style="min-height: 100%;">
                <GasSavingsGroupCard title="Úspora za zvolený interval" :valueMon="totmon" :valueCo2="totco2" style="height: 33%;" />
            </div>
        </div>

        <DashboardFooter />
    </div>
</template>

<script>
import { eventBus } from '../store.js'

import DashboardHeader from './DashboardHeader.vue'
import DashboardFooter from './DashboardFooter.vue'

import ScenarioSelector from './ScenarioSelector.vue'

import GasGraphTile from './GasGraphTile.vue'
import GasSavingsGroupCard from './GasSavingsGroupCard.vue'

export default {
    name: 'DashboardScenarios',
    components: {
        DashboardHeader,
        DashboardFooter,
        ScenarioSelector,
        GasGraphTile,
        GasSavingsGroupCard
    },

    data() {
        return {
            // First set all to null, since we technically don't know, what years are selected.

            gasUseData: [
                {
                    name: 'skutečná spotřeba',

                    x: null,
                    y: null,

                    type: 'bar',

                    /*width: 0.75,*/
                    marker: {
                        color: 'rgb(161, 222, 144)'
                    }
                },

                {
                    name: "modelová spotřeba",

                    x: null,
                    y: null,

                    type: 'scatter',
                    mode: 'lines',

                    line: {
                        color: 'rgb(250, 110, 79)',
                        width: 1.5,

                        shape: 'hv' // Or 'vh' ? or smoothing or ... https://plotly.com/javascript/reference/#scatter-line-shape
                    }
                }
            ],

            gasSaveData: [
                {
                    name: 'celková úspora/ztráta',

                    x: null,
                    y: null,
                    yaxis: 'y',

                    type: 'bar',

                    marker: {
                        color: null,
                        cmin: -1,
                        cmax: 1,
                        colorscale: [[0, 'rgba(250, 110, 79, 0.5)'], [1, 'rgb(161, 222, 144)']]
                    }
                },

                {
                    name: 'denní úspora/ztráta',

                    x: null,
                    y: null,
                    yaxis: 'y2',

                    type: 'scatter',
                    mode: 'lines',

                    line : {
                        color: 'rgb(151, 132, 209)',
                        width: 1.5,

                        shape: 'hv' // See ^^^^
                    }
                }
            ],

            totmon: null,
            totco2: null
        }
    },

    created: function() {
        // Handler of asynchronous data fetching

        eventBus.$on('onDataForIntervalRetrieved', (data) => {

            if(!data || typeof data === "string") {
                this.$root.$bvToast.toast(
                    'Zvolený interval nebylo možné vypočítat. Prosím, zvolte jiný nebo kontaktujte správce aplikace.', {
                        title: 'Interval nebylo možné vypočítat',
                        variant: 'danger',
                        solid: true
                    }
                )

                return
            }

            // Simply insert totals
            this.totmon = data.totals.totmon
            this.totco2 = data.totals.totco2

            // Calculate other data
            var x = []

            var yReal = []
            var yModel = []
            var yTotal = []
            var yTotalColorSpace = []
            var yDaily = []

            // First, format the data
            for(var i = 0; i < data.gas.length; i++) {
                x.push(data.gas[i].day)

                yReal.push(data.gas[i].gasvol)
                yModel.push(data.gas[i].refgasvol)

                yTotal.push(data.gas[i].cumval)
                yTotalColorSpace.push((data.gas[i].cumval >= 0) ? 1 : -1)

                yDaily.push(data.gas[i].val)
            }

            // Then insert the data into the charts
            this.gasUseData[0].x = x
            this.gasUseData[0].y = yReal

            this.gasUseData[1].x = x
            this.gasUseData[1].y = yModel

            this.gasSaveData[0].x = x
            this.gasSaveData[0].y = yTotal
            this.gasSaveData[0].marker.color = yTotalColorSpace

            this.gasSaveData[1].x = x
            this.gasSaveData[1].y = yDaily

            this.createCustomLegend()
        })
    },

    methods: {
        createCustomLegend() {
            let legendComp = document.getElementById('gas-save-chart').getElementsByClassName('legendpoints')[0]
            if(legendComp != undefined) {
                legendComp.innerHTML = '<g transform="translate(14,-6)"><path style="fill:#a1de90;fill-opacity:1;stroke:none" d="M 0,0 H 12 V 12 H 0 Z" /><path style="fill:#fa6e4f;fill-opacity:1;stroke:none" d="M 12,12 H 0 L 12,0 Z"/></g>'
            }
        },
        
        calculate() {
            if(this.$store.state.refinterval != null && this.$store.state.studyinterval != null) {
                // TODO check study > ref OR NOT
                this.getDataForInterval(this.$store.state.refinterval, this.$store.state.studyinterval)
            } else {
                this.$root.$bvToast.toast(
                    'Pro výpočet dat nejdřív zvolte srovnávaný a referenční interval. Oba intervaly musí mít délku alespoň 7 dní.', {
                        title: 'Nebyly zvoleny intervaly',
                        variant: 'danger',
                        solid: true
                    }
                )
            }
        }
    }
}
</script>