<template>
    <div class="d-flex d-md-inline justify-content-between align-items-center mr-md-2">
        <span class="d-inline mr-1 mb-0 h5 align-middle">{{title}}</span>
        <DateRangePicker class="align-middle" control-container-class="form-control form-control-sm reportrange-text"
            :locale-data="localeData" :ranges="ranges" :date-format="formatDate"
            v-model="selectedInterval" :key="rerenderKey" @update="inputChanged"></DateRangePicker>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    name: 'ScenarioSelector',
    components: {
        DateRangePicker
    },
    props: {
        title: {
            required: true,
            type: String
        },

        id: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            localeData: {
                firstDay: 1,
                format: "dd.mm.yyyy"
            },
            ranges: null,
            selectedInterval: {
                startDate: null,
                endDate: null
            },
            rerenderKey: 0
        }
    },

    created() {

        let today = new Date()
        today.setHours(0, 0, 0, 0)

        let lastWeek = new Date()
        lastWeek.setDate(lastWeek.getDate() - 7)
        lastWeek.setHours(0, 0, 0, 0)

        let lastMonth = new Date()
        lastMonth.setDate(lastMonth.getDate() - 30)
        lastMonth.setHours(0, 0, 0, 0)

        this.ranges = {
            'This Year': [new Date(today.getFullYear(), 0, 1), today],
            'Last Year': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear(), 0, 0)],
            'This Month': [new Date(today.getFullYear(), today.getMonth(), 1), today],
            'Last Month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'Last 30 Days': [lastMonth, today],
            'Last Week': [lastWeek, today]
        }

    },

    methods: {
        // Disable future dates
        formatDate(classes, date) {
            if(!classes.disabled) {
                classes.disabled = date.getTime() > new Date()
            }

            return classes
        },

        inputChanged(data) {
            let days = (data.endDate - data.startDate) / 86400000

            if(days < 7) {
                this.$root.$bvToast.toast(
                    'Interval má nedostatečnou délku aby umožnil výpočet. Prosím zvolte jiný interval o délce alespoň 7 dní.', {
                        title: 'Interval má nedostatečnou délku',
                        variant: 'danger',
                        solid: true
                    }
                )

                this.selectedInterval.startDate = null
                this.selectedInterval.endDate = null
                this.rerenderKey += 1; // Force rerender
            } else {
                this.selectedInterval.startDate = data.startDate
                this.selectedInterval.endDate = data.endDate
            }

            // Store the value of selected year
            let sd = this.selectedInterval.startDate
            let ed = this.selectedInterval.endDate
            let d = [sd,ed]

            if(this.id === 'refinterval') {
                this.$store.commit('setRefInterval', d)
            } else if (this.id === 'studyinterval') {
                this.$store.commit('setStudyInterval', d)
            }
        }
    }
}
</script>